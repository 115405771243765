.list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--clr-title);
  opacity: 0.75;
  max-width: 600px;
  margin: 0 auto;
}

.item {
  padding: 0.75rem;
  color: black;
  margin-bottom: 1px solid white;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid white;
  cursor: pointer;
}
