.checkboxContainer {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.checkboxContainer label {
  cursor: pointer;
  display: flex;
}

.checkboxContainer input[type='checkbox'] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.checkboxContainer label::before {
  content: '';
  width: 1rem;
  height: 1rem;
  border: 0.05rem solid white;
  border-radius: 0.15em;
  margin-right: 0.5em;
}

.checkboxContainer input[type='checkbox']:checked + label::before {
  background-color: var(--clr-detail);
}
