.background {
  outline: 1px solid white;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
  max-width: 600px;
  margin: 0 auto;
}

.focused {
  outline: 3px solid var(--clr-detail);
}

.error {
  outline: 3px solid var(--clr-error);
}

.button {
  background-color: var(--clr-background);
  color: white;
  border: 0;
  cursor: pointer;
  font-weight: bold;
  width: 20%;
}

.input {
  border: 0;
  border-radius: 5px;
  height: 10vw;
  max-height: 50px;
  width: 70%;
  padding-left: 0.5rem;
  font-size: large;
}

.input:focus {
  outline: 0;
}

.input[placeholder] {
  text-overflow: ellipsis;
  font-size: 1rem;
}

@media (max-width: 510px) {
  .input[placeholder] {
    font-size: 0.6rem;
  }
}
