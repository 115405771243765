.radioContainer {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1em auto;
  width: fit-content;
  min-width: 350px;
}

.radioContainer label {
  cursor: pointer;
  display: flex;
}

.radioContainer input[type='radio'] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.radioContainer label::before {
  content: '';
  width: 1rem;
  height: 1rem;
  border: 0.05rem solid white;
  border-radius: 0.5em;
  margin-right: 0.5em;
}

.radioContainer input[type='radio']:checked + label::before {
  background-color: var(--clr-detail);
}

.radioOption {
  margin-bottom: 1rem;
}

@media (min-width: 400px) {
  .radioContainer {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    max-width: 450px;
  }
}